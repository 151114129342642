import React, { useContext } from 'react';

// context
import { DeviceContext } from '~/contexts/DeviceContext';

// types
import type { MultiPageHeroVariantProps } from '~/components/publicWebsiteV2/common/MultiPageHeroContainer/types';

// utils
import { getWebsitePlaceholderAsset } from '~/components/manage/EditWebsite/common/HeroCustomizer/utils';

// components
import WebsiteSlideshow from '~/components/publicWebsiteV2/common/WebsiteSlideshow';

// styles
import { BaseHero } from '~/components/publicWebsiteV2/common/MultiPageHeroContainer/MultiPageHeroContainer.styles';
import {
  BottomPhotoSized,
  DesktopImagesContainer,
  StyledDesktopBaseHero,
  TopPhotoSized,
  STACKED_PHOTO_LENGTH,
} from '~/components/publicWebsiteV2/common/MultiPageHeroContainer/components/MultiPageHeroStacked/MultiPageHeroStacked.styles';
import { PageAnimationWrapper } from '~/components/publicWebsiteV2/common/PageAnimationWrapper/PageAnimationWrapper';
import { useAnimationContext } from '~/components/manage/EditWebsite/EditWebsiteAddEffects/components/AnimationContext/AnimationContext';
import { getAnimationPropsStackedHero } from '~/components/publicWebsiteV2/common/OverlayAnimation/pageAnimationUtils';

const MultiPageHeroStacked: React.FC<MultiPageHeroVariantProps> = ({
  images,
  inPreview,
  overrideMobileHeight,
}) => {
  const { device } = useContext(DeviceContext);

  const isDesktop = inPreview === 'DESKTOP' || (!inPreview && device?.isDesktop());

  const topPhoto = images?.find((image) => image?.position === 1);
  const bottomPhoto = images?.find((image) => image?.position === 2);

  const slideshowImages = [
    topPhoto ? topPhoto?.image_url : getWebsitePlaceholderAsset('STACKED', isDesktop, 1),
    bottomPhoto ? bottomPhoto?.image_url : getWebsitePlaceholderAsset('STACKED', isDesktop, 2),
  ];

  const { pageAnimation } = useAnimationContext();

  const hasAnimation = !!pageAnimation;

  const delayAnimationProps = getAnimationPropsStackedHero(pageAnimation, true);
  return isDesktop ? (
    <StyledDesktopBaseHero
      overrideMobileHeight={overrideMobileHeight}
      data-testid="MultiPageHeroStacked"
    >
      <DesktopImagesContainer>
        <PageAnimationWrapper
          animationProps={{
            isHero: true,
            direction: { wipeDirection: 'down', panDirection: 'left' },
            ...getAnimationPropsStackedHero(pageAnimation),
          }}
          style={{ zIndex: 10, position: 'relative', top: '-40px', right: '-40px' }}
        >
          <TopPhotoSized
            src={
              topPhoto ? topPhoto?.image_url : getWebsitePlaceholderAsset('STACKED', isDesktop, 1)
            }
            alt="Hero Image 1"
            containerWidth={STACKED_PHOTO_LENGTH}
            hasAnimation={hasAnimation}
          />
        </PageAnimationWrapper>

        <PageAnimationWrapper
          animationProps={{
            isHero: true,
            direction: { wipeDirection: 'down', panDirection: 'right' },
            ...delayAnimationProps,
          }}
          hideDuringDelayValue={delayAnimationProps.delay ? delayAnimationProps.delay + 0.1 : 0}
          style={{ zIndex: 20, position: 'relative', bottom: '-40px', left: '-40px' }}
        >
          <BottomPhotoSized
            hasAnimation={hasAnimation}
            src={
              bottomPhoto
                ? bottomPhoto?.image_url
                : getWebsitePlaceholderAsset('STACKED', isDesktop, 2)
            }
            alt="Hero Image 2"
            containerWidth={STACKED_PHOTO_LENGTH}
          />
        </PageAnimationWrapper>
      </DesktopImagesContainer>
    </StyledDesktopBaseHero>
  ) : (
    <BaseHero overrideMobileHeight={overrideMobileHeight} data-testid="MultiPageHeroStacked">
      <WebsiteSlideshow slideshowImages={slideshowImages} inPreview={inPreview} />
    </BaseHero>
  );
};

export default MultiPageHeroStacked;
